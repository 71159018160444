import { Grid, Button, Typography, IconButton } from "@mui/material";
import MaterialTable from "../../components/MaterialTable";
import { useTranslation } from "react-i18next";
import { useMemo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import dotenv from "react-dotenv";
import { useAuth } from "../../hooks/useAuth";

const SubscriptionsSpecificMedicalGroupPage = (props) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const handleExportData = () => {};
  const { id, month, year } = useParams();
  const [errors, setErrors] = useState({});
  const [subData, setSubData] = useState({});
  const [billingData, setBillingData] = useState({});
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const columnsBilling = useMemo(() => [
    {
      id: "total",
      header: t("totalUSD"),
      key: "total",
      accessorKey: "total",
      size: 150,
    },
    {
      id: "total_vs",
      header: t("totalVS"),
      key: "total_vs",
      accessorKey: "total_vs",
      size: 150,
    },
    {
      id: "total_checkout",
      header: t("total_checkout"),
      key: "total_checkout",
      accessorKey: "total_checkout",
      size: 150,
    },
  ]);

  const columnsMedicalGroup = useMemo(() => [
    {
      id: "month",
      header: t("date"),
      key: "month",
      accessorKey: "month",
      size: 150,
    },
    {
      id: "id_patient",
      header: t("patient_id"),
      key: "id_patient",
      accessorKey: "id_patient",
      size: 150,
    },
    {
      id: "total",
      header: t("totalUSD"),
      key: "total",
      accessorKey: "total",
      size: 150,
    },
    {
      id: "total_vs",
      header: t("totalVS"),
      key: "total_vs",
      accessorKey: "total_vs",
      size: 150,
    },
    {
      id: "total_to_pay",
      header: t("total_checkout"),
      key: "total_to_pay",
      accessorKey: "total_to_pay",
      size: 150,
    },
  ]);

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);
    const url = new URL(
      `/institutions/v1/payments/subscriptions/medical-group/${id}`,
      dotenv.API_URL
    );
    if (month) url.searchParams.set("month", `${month}`);
    if (year) url.searchParams.set("year", `${year}`);
    url.searchParams.set("page", `${pagination.pageIndex + 1}`);
    url.searchParams.set("size", `${pagination.pageSize}`);
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
    url.searchParams.set("globalFilter", globalFilter ?? "");
    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));

    axios
      .get(url.href, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => {
        setSubData(response.data.data);
        setRowCount(response.data.total);
        setIsError(false);
      })
      .catch(() => {
        props.showMessage(
          t("error_fetching_data"),
          () => {
            setIsError(true);
          },
          "error"
        );
      })
      .finally(() => {
        setIsLoading(false);
        setIsRefetching(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [columnFilters, globalFilter, pagination, sorting]);

  const styles = {
    buttons: {
      display: "flex",
      width: "207px",
      padding: "12px 26px",
      justifyContent: "center",
      gap: "10px",
      borderRadius: "15px",
      color: "white",
      background: "#3657D9",
    },
    blueTitle: {
      color: "#3657D9",
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "400",
      letterSpacing: "0.32px",
      paddingBottom: "16px",
    },
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={10} sx={{ alignContent: "center" }}>
        <Typography sx={styles.blueTitle}>
          <b>{`${t("business_model")}:`}</b>{" "}
          <span style={{ color: "#869AE8" }}>{t("patient")}</span>
        </Typography>
      </Grid>
      <Grid item xs={2} sx={{ alignContent: "center" }}>
        <Button
          sx={[styles.buttons, { width: "155px" }]}
          color="primary"
          onClick={handleExportData}
          endIcon={<img src="/export-white.png" />}
          variant="contained"
        >
          {t("export")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <MaterialTable
          enableFilters={false}
          enablePagination={false}
          columns={columnsBilling}
          data={billingData}
        />
      </Grid>
      <Grid item xs={12}>
        <MaterialTable
          columns={columnsMedicalGroup}
          data={subData}
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          rowCount={rowCount}
          state={{
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default SubscriptionsSpecificMedicalGroupPage;
