import { Grid, Button, IconButton, Typography } from "@mui/material";
import MaterialTable from "../../components/MaterialTable";
import DateFilterSelect from "./components/DateFilterSelect";
import { useTranslation } from "react-i18next";
import { useMemo, useState, useEffect } from "react";
import axios from "axios";
import dotenv from "react-dotenv";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import WithMessages from "../../components/WithMessages";
import moment from "moment";

const SubscriptionsIndexPage = (props) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [customerData, setCustomerData] = useState({});
  const [billingData, setBillingData] = useState({});
  const handleExportData = () => {};
  const [errors, setErrors] = useState({});
  const [params, setParams] = useState();
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    year: moment().year(),
    month: moment().month() + 1,
  });
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const typePlan = {
    1: t("patient"),
    2: t("provider"),
  };

  const serialize = (obj) => {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  };

  const billingColumns = useMemo(() => [
    {
      id: "total",
      header: t("totalUSD"),
      key: "total",
      accessorKey: "total",
      size: 150,
      Cell: (cell) =>
        +cell.row.original.total_vs + +cell.row.original.total_to_pay,
    },
    {
      id: "total_vs",
      header: t("totalVS"),
      key: "total_vs",
      accessorKey: "total_vs",
      size: 150,
    },
    {
      id: "total_to_pay",
      header: t("total_checkout"),
      key: "total_to_pay",
      accessorKey: "total_to_pay",
      size: 150,
      Cell: (cell) => {
        //TODO: Calculate patient subscriptions
        return cell.row.original.pathology_data[0].type_plan == 1 ? 0 : 0;
      },
    },
  ]);
  const customerColumns = useMemo(() => [
    {
      id: "month",
      header: t("month"),
      key: "month",
      accessorKey: "month",
      size: 150,
      Cell: (cell) =>
        cell.row.original.pathology_data[0].month
          ? cell.row.original.pathology_data[0].month
          : "",
    },
    {
      id: "customer",
      header: t("grouper"),
      key: "customer_name",
      accessorKey: "customer_name",
      size: 150,
    },
    {
      id: "business_model",
      header: t("business_model"),
      key: "business_model",
      accessorKey: "business_model",
      size: 150,
      Cell: (cell) => typePlan[cell.row.original.pathology_data[0].type_plan],
    },
    {
      id: "cantidad_suscripciones_icc",
      header: `${t("subs_per_month")} ICC`,
      key: "cantidad_suscripciones",
      accessorKey: "cantidad_suscripciones",
      size: 150,
      Cell: (cell) => {
        let filtered = cell.row.original.pathology_data.filter(
          (pathology) => pathology.pathology == 1
        );
        return filtered[0] ? filtered[0].subscription_count : 0;
      },
    },
    {
      id: "cantidad_suscripciones_hta",
      header: `${t("subs_per_month")} HTA`,
      key: "cantidad_suscripciones",
      accessorKey: "cantidad_suscripciones",
      size: 150,
      Cell: (cell) => {
        let filtered = cell.row.original.pathology_data.filter(
          (pathology) => pathology.pathology == 2
        );
        return filtered[0] ? filtered[0].subscription_count : 0;
      },
    },
    {
      id: "total",
      header: t("totalUSD"),
      key: "total",
      accessorKey: "total",
      size: 150,
      Cell: (cell) =>
        cell.row.original.total_vs && cell.row.original.total_to_pay
          ? +cell.row.original.total_vs + +cell.row.original.total_to_pay
          : 0,
    },
    {
      id: "total_VS",
      header: t("totalVS"),
      key: "total_VS",
      accessorKey: "total_VS",
      size: 150,
      Cell: (cell) =>
        cell.row.original.total_vs ? cell.row.original.total_vs : 0,
    },
    {
      id: "total_checkout",
      header: t("total_checkout"),
      key: "total_checkout",
      accessorKey: "total_checkout",
      size: 150,
      Cell: (cell) => {
        //TODO: Calculate patient subscriptions
        return cell.row.original.pathology_data[0].type_plan == 1 ? 0 : 0;
      },
    },
    {
      id: "actions",
      accessorKey: "actions",
      header: t("actions"),
      size: 50,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: (cell) =>
        ["admin-vs"].some((r) => props.role.includes(r)) ? (
          <IconButton
            onClick={() =>
              navigate(
                `/subscriptions/${cell.row.original["customer_id"]}/${filters.year}/${filters.month}`,
                {
                  state: {
                    customer: cell.row.original.name,
                  },
                }
              )
            }
            aria-label="view"
          >
            <img src="/view.png" alt="view" />
          </IconButton>
        ) : (
          <></>
        ),
    },
  ]);

  const styles = {
    buttons: {
      display: "flex",
      width: "207px",
      padding: "12px 26px",
      justifyContent: "center",
      gap: "10px",
      borderRadius: "15px",
      color: "white",
      background: "#3657D9",
    },
  };

  const fetchData = async () => {
    if (!params) return;
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);
    const url = new URL(
      `/institutions/v1/payments/subscriptions?${params}`,
      dotenv.API_URL
    );
    url.searchParams.set("page", `${pagination.pageIndex + 1}`);
    url.searchParams.set("size", `${pagination.pageSize}`);
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
    url.searchParams.set("globalFilter", globalFilter ?? "");
    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    axios
      .get(url.href, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer "  + user.token,
        },
      })
      .then((response) => {
        setCustomerData(response.data.data);
        setRowCount(response.data.total);
        setIsError(false);
      })
      .catch(() => {
        props.showMessage(
          t("error_fetching_data"),
          () => {
            setIsError(true);
          },
          "error"
        );
      }).finally(() => {
        setIsLoading(false);
        setIsRefetching(false);
      });;
  };

  useEffect(() => {
    if (filters.customer_id == 0) {
      delete filters.customer_id;
    }
    if (filters.type_plan == 0) {
      delete filters.type_plan;
    }
    setParams(serialize(filters));
  }, [filters]);

  useEffect(() => {
    fetchData();
  }, [params, columnFilters, globalFilter, pagination, sorting]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={10}>
        <DateFilterSelect setFilters={setFilters} filters={filters} />
      </Grid>
      <Grid item xs sx={{ alignContent: "center" }}>
        <Button
          sx={[styles.buttons, { width: "155px" }]}
          color="primary"
          onClick={handleExportData}
          endIcon={<img src="/export-white.png" />}
          variant="contained"
        >
          {t("export")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <MaterialTable
          enableFilters={false}
          enablePagination={false}
          columns={billingColumns}
          data={billingData}
        />
      </Grid>
      <Grid item xs={12}>
        <MaterialTable
          columns={customerColumns}
          data={customerData}
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          rowCount={rowCount}
          state={{
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default WithMessages(SubscriptionsIndexPage);
