import {
  Typography,
  Grid,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import SelectWithPrevNext from "../../../components/SelectWithPrevNext";
import { useTranslation } from "react-i18next";
import { SelectSx } from "../../../theme/vs-theme";
import { FormControl } from "@mui/base";
import { useState, useEffect } from "react";
import axios from "axios";
import dotenv from "react-dotenv";
import { useAuth } from "../../../hooks/useAuth";
import moment from "moment";

const DateFilterSelect = (props) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [customer_id, setCustomer_id] = useState([]);
  const [businessModel, setBusinessModel] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [isError, setIsError] = useState("");

  const handleChange = (event) => {
    props.setFilters({
      ...props.filters,
      [event.target.name]: event.target.value,
    });
  };

  const handleCustomer = (event) => {
    setCustomer_id(event.target.value);
    handleChange(event);
  };
  const handleBusinessModel = (event) => {
    setBusinessModel(event.target.value);
    handleChange(event);
  };

  const months = [
    { value: 1, label: "january" },
    { value: 2, label: "february" },
    { value: 3, label: "march" },
    { value: 4, label: "april" },
    { value: 5, label: "may" },
    { value: 6, label: "june" },
    { value: 7, label: "july" },
    { value: 8, label: "august" },
    { value: 9, label: "september" },
    { value: 10, label: "october" },
    { value: 11, label: "november" },
    { value: 12, label: "december" },
  ];
  const fillYears = () => {
    let values = [];
    for (let i = 2022; i <= moment().year(); i++) {
      values.push({ value: i });
    }
    return values;
  }

  const years = fillYears();
  
  const styles = {
    blueTitle: {
      color: "#3657D9",
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "600",
      letterSpacing: "0.32px",
      textAlign: "center",
    },
  };

  const fetchData = async () => {
    const url = new URL("/institutions/v1/customers", dotenv.API_URL);
    axios
      .get(url.href, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => {
        setCustomers(response.data.data);
      })
      .catch(() => setIsError(true));
  };

  useEffect(() => {
    setCustomer_id(0);
    setBusinessModel(0);
    fetchData();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={3} sx={{ alignContent: "center" }}>
            <Typography sx={styles.blueTitle}>{t("filter_by")}</Typography>
          </Grid>
          <Grid item xs={5}>
            <FormHelperText sx={{ color: "#869AE8" }}>
              {t("date")}
            </FormHelperText>
            <SelectWithPrevNext
              name="month"
              values={months}
              filters={props.filters}
              setFilters={props.setFilters}
            />
          </Grid>
          <Grid item xs={4}>
            <FormHelperText sx={{ color: "#869AE8" }}> </FormHelperText>
            <SelectWithPrevNext
              name="year"
              values={years}
              filters={props.filters}
              setFilters={props.setFilters}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid item container>
          <Grid item xs={6}>
            <FormControl variant="outlined">
              <FormHelperText sx={{ color: "#869AE8" }}>
                {t("grouper")}
              </FormHelperText>
              <Select
                name="customer_id"
                value={customer_id}
                onChange={handleCustomer}
                sx={SelectSx}
              >
                <MenuItem key="0" value="0">
                  {t("all").toUpperCase()}
                </MenuItem>
                {customers.map((customer) => {
                  return (
                    <MenuItem key={customer.id} value={customer.id}>
                      {customer.business_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined">
              <FormHelperText sx={{ color: "#869AE8" }}>
                {t("business_model")}
              </FormHelperText>
              <Select
                name="type_plan"
                value={businessModel}
                onChange={handleBusinessModel}
                sx={SelectSx}
              >
                <MenuItem value={0}>{t("all").toUpperCase()}</MenuItem>
                <MenuItem value={1}>{t("patient")}</MenuItem>
                <MenuItem value={2}>{t("provider")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DateFilterSelect;
