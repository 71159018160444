import { useState, useEffect, useMemo } from "react";
import MaterialTable from "../../components/MaterialTable";
import { Button, Tooltip, Avatar, Grid, Chip, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import axios from "axios";
import dotenv from "react-dotenv";
import { useTranslation } from "react-i18next";

const MedicalGroupsIndexPage = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const typeValues = [
    { value: true, text: t("test") },
    { value: false, text: t("productive") },
  ];

  const columns = useMemo(
    () => [
      {
        id: "name",
        accessorKey: "name",
        header: t("name_medicalequipe"),
        key: "name",
        size: 300,
        Cell: (cell) => (
          <Grid spacing={1} container>
            <Grid item xs={3}>
              <Avatar
                alt="avatar"
                src={cell.row.original?.avatar?.url}
                sx={{
                  width: "50px",
                  height: "50px",
                  border: 4,
                  backgroundColor: "#D9D9D9",
                  borderColor: "white",
                }}
              />
            </Grid>
            <Grid item xs={9} sx={{ mt: 2 }}>
              {cell.row.original.name}
            </Grid>
          </Grid>
        ),
      },
      {
        id: "pathology",
        accessorKey: "pathology",
        header: t("patology"),
        Cell: (cell) => (
          <>
            <Chip
              label={cell.row.original?.pathology?.name}
              variant="outlined"
            />
          </>
        ),
        size: 100,
      },
      {
        id: "users",
        accessorKey: "users",
        header: t("medics"),
        Cell: (cell) => (
          <>
            {cell.row.original.users.map((user) => (
              <Chip
                key={user.id}
                avatar={<Avatar alt="avatar" src={user.avatar?.url} />}
                label={user.name + " " + user.lastname}
                variant="outlined"
              />
            ))}
          </>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 350,
      },
      {
        id: "code",
        accessorKey: "code",
        header: t("medical_code"),
        Cell: (cell) => (
          <>
            <b>{cell.row.original.code}</b>
          </>
        ),
        size: 150,
      },
      {
        id: "fake",
        accessorKey: "fake",
        header: t("type"),
        filterVariant: "select",
        filterSelectOptions: typeValues,
        Cell: (cell) => (
          <>
            <b>{cell.row.original.fake ? t("test") : t("productive")}</b>
          </>
        ),
        size: 150,
      },
      {
        id: "actions",
        accessorKey: "actions",
        header: t("actions"),
        size: 150,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        Cell: (cell) =>
          ["superadmin", "admin-institution", "admin-customer"].some((r) =>
            props.role.includes(r)
          ) ? (
            <>
              <Tooltip title={t("edit_medical_group")} placement="top">
                <IconButton
                  onClick={() =>
                    navigate(`/medical-groups/${cell.row.original.id}`, {
                      state: {
                        medicalGroup: cell.row.original.name,
                      },
                    })
                  }
                  aria-label="edit"
                >
                  <img
                    src="/edit.png"
                    alt="edit"
                    style={{ width: 18, height: 18 }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("customize_parameters")} placement="top">
                <IconButton
                  onClick={() =>
                    navigate(`/medical-groups/${cell.row.original.id}/params`, {
                      state: {
                        medicalGroup: cell.row.original.name,
                      },
                    })
                  }
                  aria-label="edit"
                >
                  <img
                    src="/params.png"
                    alt="parameters"
                    style={{ width: 20, height: 20 }}
                  />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <></>
          ),
      },
    ],
    [navigate, t]
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsRefetching(true);
      setIsError(false);

      const url = new URL("/institutions/v1/medical-groups", dotenv.API_URL);
      url.searchParams.set("page", `${pagination.pageIndex + 1}`);
      url.searchParams.set("size", `${pagination.pageSize}`);
      url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      url.searchParams.set("globalFilter", globalFilter ?? "");
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));

      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          setData(response.data.data);
          setRowCount(response.data.total);
          setIsError(false);
        })
        .catch(() => setIsError(true))
        .finally(() => {
          setIsLoading(false);
          setIsRefetching(false);
        });
    };
    fetchData();
  }, [columnFilters, globalFilter, pagination, sorting]);

  return (
    <div>
      <div>
        <MaterialTable
          columns={columns}
          data={data}
          title="Institutions"
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: "error",
                  children: t("error_fetching_data"),
                }
              : undefined
          }
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          rowCount={rowCount}
          state={{
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
          }}
        />
      </div>
      <br />
      {["superadmin", "admin-institution", "admin-customer"].some((r) =>
        props.role.includes(r)
      ) ? (
        <Button
          variant="contained"
          onClick={() => navigate("/medical-groups/add")}
          color="success"
        >
          {t("add_medical_group")}
        </Button>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MedicalGroupsIndexPage;
