import { createTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

const TextField = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(2),
    color: "#869AE8",
  },
  "& .MuiFormLabel-root": {
    color: "#869AE8",
  },
  "& .MuiInputBase-input": {
    padding: "10px 12px",
    color: "#869AE8",
  },
  "& .MuiInputBase-inputAdornedStart": {
    padding: "10px 0px",
    color: "#869AE8",
  },
  borderRadius: "8px",
  backgroundColor: "#FCFCFC",
  border: "1px solid #869AE8",
  color: "#869AE8",
}));

const SelectSx = {
  "label + &": {
    marginTop: "16px",
  },
  "& .MuiFormLabel-root": {
    color: "#869AE8",
  },
  "& .MuiSelect-select": {
    backgroundColor: "#FCFCFC",
    borderRadius: 2,
    border: "1px solid #869AE8",
    padding: "10px 12px",
    color: "#869AE8",
  },
  boxShadow: "none",
  "& .MuiOutlinedInput-notchedOutline": { border: 0 },
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#3657D9",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#FFFFFF",
      contrastText: "#ffffff",
    },
    error: {
      main: "#D04444",
      contrastText: "#ffffff",
    },
    success: {
      main: "#5EC460",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
  components: {
    MuiToolbar: {
      styleOverrides: { root: { borderRadius: "0px 0px 24px 24px" } },
    },
    MuiFormControl: { styleOverrides: { root: { width: "100%" } } },
    MuiButton: {
      styleOverrides: {
        root: {
          width: "100%",
          padding: "12px",
          fontSize: 15,
          fontWeight: "bold",
          textTransform: "none",
          borderRadius: "15px",
          boxShadow: "none",
          "&:hover": {
            backgroundColor: "#b2b2ff",
            opacity: 0.9,
            color: "#fcf8cf",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "25px",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#869AE8",
        },
      },
    },
  },
  "label + &": {
    color: "#869AE8",
  },
  "& .MuiFormLabel-root": {
    color: "#869AE8",
  },
  "& .MuiInputBase-input": {
    borderRadius: 8,
    backgroundColor: "#FCFCFC",
    border: "1px solid #869AE8",
    padding: "10px 12px",
    color: "#869AE8",
  },
  "& .MuiPaper-root": {
    padding: 4,
    width: "100%",
    borderRadius: 4,
  },
});

export { TextField };
export { SelectSx };
export default theme;
