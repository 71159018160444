import { Select, MenuItem, IconButton, Stack } from "@mui/material";
import { useState, useEffect } from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { FormControl } from "@mui/base";
import { useTranslation } from "react-i18next";

const SelectWithPrevNext = (props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [index, setIndex] = useState(0);
  const goNext = () => {
    index == props.values.length - 1 ? setIndex(0) : setIndex(index + 1);
  };
  const goPrev = () => {
    index == 0 ? setIndex(props.values.length - 1) : setIndex(index - 1);
  };

  useEffect(() => {
    setIndex(
      props.values.findIndex((x) => x.value == props.filters[props.name])
    );
    setValue(props.values[props.filters[props.name]]);
  }, []);

  useEffect(() => {
    setValue(props.values[index].value);
    props.setFilters({
      ...props.filters,
      [props.name]: props.values[index].value,
    });
  }, [index]);

  const styles = {
    stack: {
      borderRadius: "12px",
      backgroundColor: "#FCFCFC",
      border: "1px solid #869AE8",
      color: "#869AE8",
      justifyContent: "space-between",
    },
  };

  const SelectSx = {
    "label + &": {
      marginTop: "16px",
    },
    "& .MuiFormLabel-root": {
      color: "#869AE8",
    },
    "& .MuiSelect-select": {
      backgroundColor: "#FCFCFC",
      borderRadius: 0,
      border: "none",
      color: "#869AE8",
      padding: "10px 0px",
    },
    boxShadow: "none",
    "& .MuiOutlinedInput-notchedOutline": { border: 0, paddingRight: 0 },
    "#customized-select": {
      paddingRight: 0, // only way to override
    },
  };

  return (
    <Stack direction="row" sx={styles.stack}>
      <IconButton sx={{ color: "#869AE8", padding: 0 }} onClick={goPrev}>
        <ArrowLeftIcon />
      </IconButton>
      <FormControl>
        <Select
          fullWidth
          id="customized-select"
          name={props.name}
          value={value}
          inputProps={{ readOnly: true, IconComponent: () => null }}
          sx={SelectSx}
        >
          {props.values.map((value) => {
            return (
              <MenuItem key={value.value} value={value.value}>
                {value.label ? t(value.label) : t(value.value)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <IconButton sx={{ color: "#869AE8", padding: 0 }} onClick={goNext}>
        <ArrowRightIcon />
      </IconButton>
    </Stack>
  );
};

export default SelectWithPrevNext;
