import {
  Paper,
  FormControl,
  FormHelperText,
  InputLabel,
  Typography,
  Grid,
  InputAdornment,
} from "@mui/material";
import { TextField } from "../../../theme/vs-theme";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

const EditPriceComponent = (props) => {
  const [totalFee, setTotalFee] = useState("");
  const { t } = useTranslation();
  const handleChange = (event) => {
    if (isNaN(event.target.value)) return;
    props.setForm({ ...props.form, [event.target.name]: event.target.value });
  };
  
  const styles = {
    paper: {
      backgroundColor: "#E7E9FF",
      padding: 4,
      width: "100%",
      margin: "16px 0",
    },
    blueTitle: {
      color: "#3657D9",
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "600",
      letterSpacing: "0.32px",
      paddingBottom: "16px",
    },
    adornment: {
      paddingLeft: "16px",
      fontWeight: 600,
      color: "#3657D9",
      userSelect: "none",
    },
    disabled: {
      border: "1px solid #869AE8",
      color: "#869AE8",
      opacity: "50%",
    },
  };
  const adornment = (
    <InputAdornment position="start">
      <Typography sx={styles.adornment}>U$S</Typography>
    </InputAdornment>
  );

  useEffect(() => {
    setTotalFee(+props.accessFee + +props.form.price);
  }, [props.accessFee, props.form.price]);

  return (
    <Paper elevation={0} sx={styles.paper}>
      <Typography sx={styles.blueTitle}>Precio</Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <FormControl required>
            <InputLabel shrink htmlFor="hta-input">
              {t("platform_access")}
            </InputLabel>
            <TextField
              sx={styles.disabled}
              startAdornment={adornment}
              disabled
              id="access-input"
              name="access"
              value={props.accessFee}
            />
            <FormHelperText
              id="name-input-error-text"
              sx={{ color: "red" }}
            ></FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl required>
            <InputLabel shrink htmlFor="name-input">
              {t("service_price")}
            </InputLabel>
            <TextField
              startAdornment={adornment}
              onChange={handleChange}
              id="price-input"
              value={props.form.price}
              name="price"
            />
            <FormHelperText
              id="name-input-error-text"
              sx={{ color: "red" }}
            ></FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl required>
            <InputLabel shrink htmlFor="name-input">
              {t("total_price")}
            </InputLabel>
            <TextField
              sx={styles.disabled}
              startAdornment={adornment}
              id="hta-input"
              name="hta"
              disabled
              value={totalFee}
            />
            <FormHelperText
              id="name-input-error-text"
              sx={{ color: "red" }}
            ></FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EditPriceComponent;
