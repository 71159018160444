import { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Select,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import Grid from "@mui/material/Unstable_Grid2";
import axios from "axios";
import dotenv from "react-dotenv";
import { TextField, SelectSx } from "../../theme/vs-theme";
import WithMessages from "../../components/WithMessages";
import { useTranslation } from "react-i18next";
import EditPriceComponent from "./components/EditPriceComponent";

const MedicalGroupsCreatePage = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [previewSrc, setPreviewSrc] = useState("");
  const [institutions, setInstitutions] = useState([]);
  const [pathologies, setPathologies] = useState([]);
  const [errors, setErrors] = useState({});
  const [accessFee, setAccessFee] = useState(0);
  const [form, setForm] = useState({
    name: "",
    pathology_id: null,
    avatar: null,
    institution_id: null,
    price: "0",
    created_by_user_id: user.user.id,
  });
  const [customerId, setCustomerId] = useState("");
  const [businessModel, setBusinessModel] = useState({});

  const handleChange = (event) => {
    if (errors[event.target.name]) {
      setErrors({ ...errors, [event.target.name]: null });
    }
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const setAvatar = (event) => {
    const file = event.target.files[0];
    if (file) {
      setErrors({ ...errors, avatar: null });
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewSrc(reader.result);
      };
      reader.readAsDataURL(file);
      setForm({ ...form, avatar: file });
    }
  };

  const fetchAccessFee = () => {
    if (!form.pathology_id || !form.institution_id) return;
    setCustomerId(
      user.user.institutions.find(
        (institution) => institution.id == form.institution_id
      ).customer_id
    );
    const url = new URL(
      `/institutions/v1/payments/plan-prices/${customerId}/${form.pathology_id}`,
      dotenv.API_URL
    );
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => {
        response.data
          ? setAccessFee(response.data.price)
          : props.showMessage(
              t("not_fetch_prices"),
              () => setAccessFee(0),
              "error"
            );
      })
      .catch(() => {
        props.showMessage(
          t("not_fetch_prices"),
          () => setAccessFee(0),
          "error"
        );
      });
  };

  const getBusinessModel = () => {
    if (!customerId) return;
    const url = new URL(
      `/institutions/v1/payments/customer/check-model/${customerId}`,
      dotenv.API_URL
    );
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => {
        setBusinessModel(response.data.type_model ?? 0);
      })
      .catch(() => {
        props.showMessage(
          t("error cargando modelo de negocio"),
          () => console.log(errors),
          "error"
        );
      });
  };

  useEffect(() => {
    fetchAccessFee();
  }, [form.pathology_id, form.institution_id]);

  useEffect(() => {
    getBusinessModel();
  }, [customerId]);

  const saveMedicalGroup = () => {
    setLoading(true);
    axios
      .post(
        new URL("/institutions/v1/medical-groups", dotenv.API_URL).href,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then(() =>
        props.showMessage(t("medical_group_was_created"), () =>
          navigate("/medical-groups")
        )
      )
      .catch((error) => setErrors(error.response.data?.errors))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (user?.token) {
      const fetchData = async () => {
        const url = new URL("/institutions/v1/pathologies", dotenv.API_URL);
        axios
          .get(url.href, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + user.token,
            },
          })
          .then((response) => {
            setPathologies(response.data);
          })
          .catch((error) => {
            props.showMessage(t("error_loading_patologies"), () =>
              navigate("/medical-groups")
            );
          });
      };
      fetchData();
    }
    if (user.user.institutions.length || user.user.customers.length) {
      const institutionArr = [];
      user.user.institutions.forEach((institutionItem) => {
        institutionArr.push({
          id: institutionItem.id,
          name: institutionItem.name,
        });
      });

      user.user.customers.forEach((customer) => {
        customer.institutions.forEach((institutionItem) => {
          institutionArr.push({
            id: institutionItem.id,
            name: institutionItem.name,
          });
        });
      });
      const uniqueInstitutions = institutionArr.filter(
        (institution, index, self) =>
          index === self.findIndex((t) => t.id === institution.id)
      );
      setInstitutions(uniqueInstitutions);
    }
  }, [user, navigate, props]);

  return (
    <div>
      <Grid container>
        <Grid>
          <Avatar
            alt="avatar"
            src={previewSrc}
            sx={{
              width: "80px",
              height: "80px",
              border: 4,
              backgroundColor: "#D9D9D9",
              borderColor: "white",
            }}
          />
        </Grid>
        <Grid xs={2} sx={{ alignContent: "center" }}>
          <label htmlFor="avatar-file">
            <input
              name="avatar"
              onClick={(event) => {
                event.target.value = null;
              }}
              onInput={setAvatar}
              accept="image/*"
              id="avatar-file"
              type="file"
              style={{ display: "none" }}
            />
            <Button
              variant="contained"
              component="span"
              color="primary"
              endIcon={<img src={"/plus.png"} alt={"Avatar"} />}
              sx={{ width: 200, ml: 3 }}
            >
              {t("add_photo")}
            </Button>
          </label>
        </Grid>
      </Grid>
      <Grid xs={12} sx={{ mt: 4 }}>
        <Paper sx={{ backgroundColor: "white", padding: 4 }} elevation={0}>
          <Grid container spacing={4}>
            <Grid xs={6}>
              <FormControl variant="standard" required>
                <InputLabel shrink htmlFor="name-input">
                  {t("name")}
                </InputLabel>
                <TextField
                  id="name-input"
                  name="name"
                  value={form.name}
                  onChange={handleChange}
                />
                <FormHelperText
                  id="name-input-error-text"
                  sx={{ color: "red" }}
                >
                  {errors?.name && errors.name[0]}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl>
                <InputLabel shrink htmlFor="institution-id-input">
                  {t("institution")}
                </InputLabel>
                <Select
                  displayEmpty
                  sx={SelectSx}
                  id="institution-id-input"
                  name="institution_id"
                  value={form.institution_id}
                  onChange={handleChange}
                >
                  {institutions.map((institution) => (
                    <MenuItem value={institution.id} key={institution.id}>
                      {institution.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText id="institution-id-input" sx={{ color: "red" }}>
                  {errors?.institution_id && errors.institution_id[0]}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid xs={3}>
              <FormControl>
                <InputLabel shrink htmlFor="patology-input">
                  {t("patologies")}
                </InputLabel>
                <Select
                  displayEmpty
                  sx={SelectSx}
                  id="patology-input"
                  name="pathology_id"
                  value={form.pathology_id}
                  onChange={handleChange}
                >
                  {pathologies.map((pathology) => (
                    <MenuItem value={pathology.id} key={pathology.id}>
                      {pathology.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText id="patology-input" sx={{ color: "red" }}>
                  {errors?.pathology_id && errors.pathology_id[0]}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          {businessModel == 1 ? (
            <EditPriceComponent
              form={form}
              setForm={setForm}
              accessFee={accessFee}
            />
          ) : (
            <></>
          )}
          <Grid container direction="row" justifyContent="flex-end" spacing={4}>
            <Grid xs={2}>
              <Button
                variant="contained"
                color="error"
                onClick={() => navigate("/medical-groups")}
              >
                {t("cancel")}
              </Button>
            </Grid>
            <Grid xs={2}>
              <Button
                variant="contained"
                color="success"
                onClick={saveMedicalGroup}
                disabled={loading}
              >
                {t("save")}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Backdrop
        sx={{ open: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default WithMessages(MedicalGroupsCreatePage);
