import {
  Button,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Box,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "../../theme/vs-theme";
import { useNavigate } from "react-router-dom";
import BusinessModelForm from "../../components/BusinessModelForm";
import axios from "axios";
import dotenv from "react-dotenv";
import { useAuth } from "../../hooks/useAuth";
import WithMessages from "../../components/WithMessages";
import styles from "./ConfigurationIndexPage.styles";

const ConfigurationIndexPage = (props) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [defaultForm, setDefaultForm] = useState({
    pathology_prices: {
      1: 0,
      2: 0,
    },
    users_test: 0,
  });
  const [form, setForm] = useState({
    pathology_prices: {
      1: 0,
      2: 0,
    },
    users_test: 0,
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const cancel = () => {
    setForm(defaultForm);
  };

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const fetchData = async () => {
    const url = new URL(
      `/institutions/v1/payments/plan-default`,
      dotenv.API_URL
    );
    axios
      .get(url.href, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => {
        let pathologyPrices = {};
        response.data.map((pathology) => {
          pathologyPrices = {...pathologyPrices, [pathology.pathology_id]: pathology.price}
         })
        setForm({...form, pathology_prices: pathologyPrices})
        setDefaultForm({...defaultForm, pathology_prices: pathologyPrices})
      })
      .catch((error) => {
        props.showMessage(
          t("error_fetching_data"),
          () => {
            setErrors(error);
          },
          "error"
        );
      });
  };

  const saveConfig = () => {
    setLoading(true);
    axios
      .post(
        new URL("/institutions/v1/payments/plan-default", dotenv.API_URL).href,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then(() =>
        props.showMessage(t("config_saved"), () => navigate("/profile"))
      )
      .catch((error) => setErrors(error.response.data?.errors))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <BusinessModelForm form={form} setForm={setForm} />
      <Paper elevation={0} sx={styles.paper}>
        <Typography sx={styles.blueTitle}>
          {t("test_patients_per_grouper")}
        </Typography>
        <Grid container>
          <Grid xs={4}>
            <FormControl variant="standard" required>
              <InputLabel shrink htmlFor="users_test">
                {t("test_patients_amount")}
              </InputLabel>
              <TextField
                onChange={handleChange}
                value={form.users_test}
                id="users_test"
                type="number"
                name="users_test"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
      <Box sx={{ display: "flex" }}>
        <Button
          onClick={cancel}
          sx={[styles.buttons, { background: "#D04444" }]}
        >
          {t("cancel_modification")}
        </Button>
        <Button
          onClick={() => saveConfig()}
          sx={[styles.buttons, { background: "#5EC360" }]}
        >
          {t("update_config")}
        </Button>
      </Box>
    </>
  );
};

export default WithMessages(ConfigurationIndexPage);
