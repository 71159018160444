const styles = {
  buttons: {
    marginTop: "20px",
    borderRadius: "15px",
    color: "white",
    margin: "20px",
  },
  title: {
    color: "#737589",
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: "600",
    letterSpacing: "0.32px",
    paddingBottom: "16px",
  },
  blueTitle: {
    color: "#3657D9",
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: "600",
    letterSpacing: "0.32px",
    paddingBottom: "16px",
  },
  paper: {
    backgroundColor: "white",
    padding: 4,
    width: "100%",
    marginBottom: 2,
  },
};

export default styles;
